import { SxProps } from '@mui/material'

export const rootSx: SxProps = {
  '& .LoginHome-loginFormContainer': {
    maxWidth: {
      xs: '100%',
      sm: 500,
    },
    width: '100%',
    mx: 'auto',
  },
}
